body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-size: 10px;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  /* background-color: rgb(0, 107, 161);
  background: linear-gradient(
    0deg,
    rgba(0, 107, 161, 1) 10%,
    rgba(8, 62, 97, 1) 120%
  ); */
  background: -webkit-linear-gradient(
    -45deg,
    rgba(76, 76, 76, 1) 0%,
    rgba(44, 44, 44, 1) 0%,
    rgba(28, 28, 28, 1) 0%,
    rgba(28, 28, 28, 1) 0%,
    rgba(17, 17, 17, 1) 0%,
    rgba(28, 28, 28, 1) 0%,
    rgba(43, 43, 43, 1) 0%,
    rgba(43, 43, 43, 1) 0%,
    rgba(89, 89, 89, 1) 0%,
    rgba(89, 89, 89, 1) 0%,
    rgba(89, 89, 89, 1) 0%,
    rgba(28, 28, 28, 1) 1%,
    rgba(71, 71, 71, 1) 1%,
    rgba(25, 25, 25, 1) 1%,
    rgba(25, 25, 25, 1) 1%,
    rgba(0, 107, 161, 1) 53%,
    rgba(8, 62, 97, 1) 80%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    135deg,
    rgba(76, 76, 76, 1) 0%,
    rgba(44, 44, 44, 1) 0%,
    rgba(28, 28, 28, 1) 0%,
    rgba(28, 28, 28, 1) 0%,
    rgba(17, 17, 17, 1) 0%,
    rgba(28, 28, 28, 1) 0%,
    rgba(43, 43, 43, 1) 0%,
    rgba(43, 43, 43, 1) 0%,
    rgba(89, 89, 89, 1) 0%,
    rgba(89, 89, 89, 1) 0%,
    rgba(89, 89, 89, 1) 0%,
    rgba(28, 28, 28, 1) 1%,
    rgba(71, 71, 71, 1) 1%,
    rgba(25, 25, 25, 1) 1%,
    rgba(25, 25, 25, 1) 1%,
    rgba(0, 107, 161, 1) 53%,
    rgba(8, 62, 97, 1) 80%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4c4c4c', endColorstr='#083e61',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */

  background-repeat: no-repeat;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
